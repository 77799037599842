import dayjs from 'dayjs';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'MMM DD YYYY';

  return date ? dayjs(date).tz().format(fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'MMM DD YYYY h:mm A';

  return date ? dayjs(date).tz().format(fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? dayjs(date).tz().valueOf() : '';
}

export function fToNow(date: InputValue) {
  return date ? dayjs(date).tz().toNow(true) : '';
}

export function fFromNow(date: InputValue) {
  return date ? dayjs(date).tz().fromNow() : '';
}
