import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export const deleteRequest = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.delete(url, { ...config });

  return res.data;
};

export const updateRequest = async (url: string, data: any) => {
  const res = await axiosInstance.post(url, data);

  return res.data;
};


// ----------------------------------------------------------------------
export type IPageParameters = {
  page: number;
  limit: number;
  orderBy?: string;
  order?: "asc" | "desc";
  urlFilters?: string;
}

export const endpoints = {
  chat: '/api/chat',
  auth: {
    me: '/auth/admin',
    login: '/auth/login',
    register: '/auth/register',
  },
  kanban: {
    getBoard: '/kanban/get-board',
    createColumn: '/kanban/create-column',
    updateColumn:'/kanban/update-column',
    moveColumn: '/kanban/move-column',
    clearColumn: '/kanban/clear-column',
    deleteColumn: (id: string) => `/kanban/column/${id}`,
    createTask: '/kanban/create-task',
    updateTask: '/kanban/update-task',
    moveTask: '/kanban/move-task',
  },
  task: {
    details: (id: string) => `/task/${id}`,
    assign: '/task/assign',
    unassign: '/task/unassign',
    updateLabels: '/task/update-labels',
    changeDueDate:'/task/change-due-date',
    addComment: '/task/add-comment',
    saveDueDate: '/task/save-due-date',
    savePriority: '/task/save-priority',
    saveName: '/task/save-name',
    delete: (id: string) => `/task/${id}`,
  },
  calendar: {
    get: (startDate: string, endDate: string, view: string) => `/calendar/get-events/?startDate=${startDate}&endDate=${endDate}&view=${view}`,
  },
  vendors: {
    all: '/vendors/all',
    page: (filters: string) => `/vendors/page/${filters}`,
    details: (id: string) => `/vendors/${id}`,
    update: '/vendors/update',
    create: '/vendors/create',
    delete: (id: string) => `/vendors/${id}/delete`,
  },
  vehicles: {
    page: (filters: string) => `/vehicles/page/${filters}`,
    details: (id: string) => `/vehicles/id/${id}`,
    update: '/vehicles/update',
    create: '/vehicles/create',
    delete: (id: string) => `/vehicles/${id}/delete`,
    changeStatus: '/vehicles/change-status',
    forVendor: (id: string) => `/vehicles/for-vendor/${id}`,
  },
  clients: {
    page: (filters: string) => `/clients/page/${filters}`,
    search: (query: string) => `/clients/search?q=${query}`,
    details: (id: string) => `/clients/${id}`,
    update: '/clients/update',
    create: '/clients/create',
    delete: (id: string) => `/clients/${id}/delete`,
    projects: (id: string) => `projects/for-client/${id}`
  },
  billingCompany: {
    page: (filters: string) => `/billing-company/page/${filters}`,
    details: (id: string) => `/billing-company/${id}`,
    create: '/billing-company/create',
    update: '/billing-company/update',
    projects: (id: string) => `/projects/for-company/${id}`
  },
  users: {
    page: (filters: string) => `/users/page/${filters}`,
    details: (id: string) => `/users/${id}`,
    create: '/users/create',
    update: '/users/update',
    delete: (id: string) => `/users/${id}/delete`,
  },
  oldProjects: {
    page: (filters: string) => `/old-projects/page/${filters}`,
    count: '/old-projects/count-by-status',
    details: (id: string) => `/old-projects/${id}`,
    delete: (id: string) => `/old-projects/${id}/delete`,
    changeStatus: (id: string) => `/old-projects/${id}/change-status`,
  },
  projects: {
    page: (filters: string) => `/projects/page/${filters}`,
    count: '/projects/count-by-status',
    details: (id: string) => `/projects/${id}`,
    create: '/projects/create',
    updateQuestionnaire: (id: string) => `/projects/${id}/update-questionnaire`,
    delete: (id: string) => `/projects/${id}/delete`,
    changeStatus: (id: string) => `/projects/${id}/change-status`,
    changeBillingCompany: (id: string) => `/projects/${id}/change-billing-company`,
  },
  bookings: {
    page: (filters: string) => `/bookings/page/${filters}`,
    forCalendar: (date: string) => `/bookings/for-calendar/${date}`,
    count: '/bookings/count-by-status',
    details: (id?: string) => id ? `/bookings/${id}`: '',
    create: '/bookings/create',
    updateQuestionnaire: (id: string) => `/bookings/${id}/update-questionnaire`,
    delete: (id: string) => `/bookings/${id}/delete`,
    changeStatus: (id: string) => `/bookings/${id}/change-status`,
    addVehicles: '/bookings/add-vehicles',
    deleteVehicles: '/bookings/delete-vehicles',
    updateVehicle: '/bookings/update-vehicle',
    addCommentToVehicle: '/bookings/add-comment-to-vehicle',
    changeVehiclesOrder: '/bookings/change-vehicles-order',
    updateMiscellaneouses: (id: string) => `/bookings/${id}/update-miscellaneouses`,
    addNote: (id: string) => `/bookings/${id}/add-note`,
    updateNote: (id: string) => `/bookings/${id}/update-note`,
    deleteNote: (id: string) => `/bookings/${id}/delete-note`,
    uploadDocument: (id: string) => `/bookings/${id}/upload-document`,
    deleteDocuments: (id: string) => `/bookings/${id}/delete-documents`,
    addTask: '/bookings/add-task',
    moveTask: '/bookings/move-task',
  },
  settings: {
    get: '/settings/all',
    update: '/settings/update',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
